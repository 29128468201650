<style>
.reportrange-text {
  min-height: 40px !important;
}
</style>
<template>
  <vx-card title="Supplier Invoice">
    <vs-button class="mb-2" @click="handleOpenForm">Create</vs-button>

    <div class="vx-row mb-6">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Warehouse</span>
        <multiselect
          class="selectExample"
          v-model="filter_warehouse"
          :options="optionFilterWarehouse"
          :multiple="false"
          :allow-empty="true"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="name"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.name }}</span>
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="filter_warehouse == null && status_submit"
          >This field is required</span
        >
      </div>

      <div class="vx-col sm:w-1/3 w-full">
        <label class="vs-input--label">Invoice Date</label>
        <div class="vx-row">
          <div class="vx-col" style="width: 87%">
            <date-range-picker
            style="min-height: 40px"
              class="w-full"
              ref="picker"
              opens="center"
              :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
              :singleDatePicker="false"
              :timePicker="false"
              :timePicker24Hour="false"
              :showWeekNumbers="false"
              :showDropdowns="false"
              :autoApply="true"
              v-model="filter_inv_date"
              :linkedCalendars="true"
            >
              <template v-slot:input="picker" style="min-width: 350px">
                {{ dateFormat(picker.startDate) }} -
                {{ dateFormat(picker.endDate) }}
              </template>
            </date-range-picker>
          </div>
          <template>
            <vx-tooltip text="reset">
              <vs-button
                type="line"
                icon-pack="feather"
                icon="icon-refresh-cw"
                @click.stop="handleResetInvoiceDateFilter()"
              />
            </vx-tooltip>
          </template>
        </div>
      </div>

      <div class="vx-col sm:w-1/3 w-full">
        <label class="vs-input--label">Due Date</label>
        <div class="vx-row">
          <div class="vx-col" style="width: 87%">
            <date-range-picker
              class="w-full"
              ref="picker2"
              opens="center"
              :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
              :singleDatePicker="false"
              :timePicker="false"
              :timePicker24Hour="false"
              :showWeekNumbers="false"
              :showDropdowns="false"
              :autoApply="true"
              v-model="filter_due_date"
              :linkedCalendars="true"
            >
              <template v-slot:input="picker2" style="min-width: 350px">
                {{ dateFormat(picker2.startDate) }} -
                {{ dateFormat(picker2.endDate) }}
              </template>
            </date-range-picker>
          </div>
          <template>
            <vx-tooltip text="reset">
              <vs-button
                type="line"
                icon-pack="feather"
                icon="icon-refresh-cw"
                @click.stop="handleResetInvoiceDueDateFilter()"
              />
            </vx-tooltip>
          </template>
        </div>
      </div>
    </div>

    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + 'md:w-1/4 w-full mb-base' : '',
          detailHide,
        ]"
      >
        <vs-tabs>
          <vs-tab label="Open">
            <open
              :key="componentKey"
              :warehouse_code="warehouse_code"
              :from_inv_date="startDateInv"
              :to_inv_date="endDateInv"
              :from_due_date="startDateDue"
              :to_due_date="endDateDue"
            ></open>
          </vs-tab>
          <vs-tab label="Confirmed">
            <div class="tab-text">
              <confirm
                :key="componentKey"
                :warehouse_code="warehouse_code"
                :from_inv_date="startDateInv"
                :to_inv_date="endDateInv"
                :from_due_date="startDateDue"
                :to_due_date="endDateDue"
              ></confirm>
            </div>
          </vs-tab>
          <vs-tab label="Valid">
            <div class="tab-text">
              <valid
                :key="componentKey"
                :warehouse_code="warehouse_code"
                :from_inv_date="startDateInv"
                :to_inv_date="endDateInv"
                :from_due_date="startDateDue"
                :to_due_date="endDateDue"
              ></valid>
            </div>
          </vs-tab>
          <vs-tab label="Pending Approval Reversal">
            <div class="tab-text">
              <pending-approval-reversal
                :key="componentKey"
                :warehouse_code="warehouse_code"
                :from_inv_date="startDateInv"
                :to_inv_date="endDateInv"
                :from_due_date="startDateDue"
                :to_due_date="endDateDue"
              ></pending-approval-reversal>
            </div>
          </vs-tab>
          <vs-tab label="Canceled">
            <div class="tab-text">
              <canceled
                :key="componentKey"
                :warehouse_code="warehouse_code"
                :from_inv_date="startDateInv"
                :to_inv_date="endDateInv"
                :from_due_date="startDateDue"
                :to_due_date="endDateDue"
              ></canceled>
            </div>
          </vs-tab>
          <vs-tab label="Payment Proposal">
            <div class="tab-text">
              <payment-proposal
                :key="componentKey"
                :warehouse_code="warehouse_code"
                :from_inv_date="startDateInv"
                :to_inv_date="endDateInv"
                :from_due_date="startDateDue"
                :to_due_date="endDateDue"
              ></payment-proposal>
            </div>
          </vs-tab>
          <vs-tab label="Closed">
            <div class="tab-text">
              <closed
                :key="componentKey"
                :warehouse_code="warehouse_code"
                :from_inv_date="startDateInv"
                :to_inv_date="endDateInv"
                :from_due_date="startDateDue"
                :to_due_date="endDateDue"
              ></closed>
            </div>
          </vs-tab>
          <vs-tab label="Reversed">
            <div class="tab-text">
              <reversed
                :key="componentKey"
                :warehouse_code="warehouse_code"
                :from_inv_date="startDateInv"
                :to_inv_date="endDateInv"
                :from_due_date="startDateDue"
                :to_due_date="endDateDue"
              ></reversed>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? detailShow + 'md:w-3/4 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>
            <!-- form component -->
            <form-create @close="handleClose"> </form-create>
          </vs-row>
        </div>
      </transition>
    </div>
  </vx-card>
</template>
<script>
import Open from "./open";
import PendingApprovalReversal from "./pending_approval_reversal";
import Canceled from "./canceled";
import Valid from "./valid";
import Confirm from "./confirm";
import Reversed from "./reversed";
import FormCreate from "./form-create";
import PaymentProposal from "./payment_proposal";
import Closed from "./../payment_proposal/closed";
import Datepicker from "vuejs-datepicker";
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  components: {
    Open,
    Canceled,
    Confirm,
    FormCreate,
    PaymentProposal,
    Valid,
    PendingApprovalReversal,
    Reversed,
    Datepicker,
    DateRangePicker,
    Closed
  },
  data() {
    return {
      detailShow: "core vx-col ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      dn_id: "",
      dn_code: "",
      cn_number: "",
      cn_date: "",
      tr_number: "",
      tr_date: "",
      componentKey: 0,
      warehouse_code: null,
      filter_warehouse: null,
      filter_inv_date: {
        startDate: null,
        endDate: null,
      },
      filter_due_date: {
        startDate: null,
        endDate: null,
      },
      status_submit: false,
      optionFilterWarehouse: [],
      startDateInv: null,
      endDateInv: null,
      startDateDue: null,
      endDateDue: null,
    };
  },
  methods: {
    handleResetInvoiceDateFilter(){
      this.filter_inv_date.startDate = null;
      this.filter_inv_date.endDate = null;
      this.startDateInv = null;
      this.endDateInv = null;
      this.componentKey += 1;
    },
    handleResetInvoiceDueDateFilter(){
      this.filter_due_date.startDate = null;
      this.filter_due_date.endDate = null;
      this.startDateDue = null;
      this.endDateDue = null;
      this.componentKey += 1;
    },
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("dddd, MMMM Do YYYY");
      }
      return a;
    },
    getOptionWarehouse() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/warehouses", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionFilterWarehouse = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Warehouse option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    handleClose() {
      this.detail = false;
      this.componentKey += 1;
    },
    handleOpenForm() {
      this.detail = true;
      this.status_detail = "form-create";
    },
  },
  mounted() {
    this.getOptionWarehouse();
  },
  watch: {
    filter_inv_date: function () {
      if (this.filter_inv_date.startDate != null) {
        this.startDateInv = moment(this.filter_inv_date.startDate).format(
          "YYYY-MM-DD"
        );
        this.endDateInv = moment(this.filter_inv_date.endDate).format(
          "YYYY-MM-DD"
        );
      }
      this.componentKey += 1;
    },
    filter_due_date: function () {
      if (this.filter_due_date.startDate != null) {
        this.startDateDue = moment(this.filter_due_date.startDate).format(
          "YYYY-MM-DD"
        );
        this.endDateDue = moment(this.filter_due_date.endDate).format(
          "YYYY-MM-DD"
        );
      }
      this.componentKey += 1;
    },
    filter_warehouse: function () {
      if (this.filter_warehouse != null) {
        this.warehouse_code = this.filter_warehouse.code;
        console.log(this.filter_warehouse.code);
      } else {
        this.warehouse_code = null;
      }
      this.componentKey += 1;
    },
  },
};
</script>
<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
    .core-leave-to

    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.highlight > td {
  background-color: #d0cdf0;
}

.con-vs-checkbox {
  margin-left: auto;
  margin-right: auto;
}
</style>